import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Deprecated:`}</strong>{` `}<inlineCode parentName="p">{`<Image />`}</inlineCode>{` has been deprecated. Please use the native HTML
`}<inlineCode parentName="p">{`<img />`}</inlineCode>{` instead. (this component does not really have any added value)`}</p>
    </blockquote>

    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Image",
        "componentName": "Image"
      }}>{`<Image src="http://placekitten.com/500/200" alt="cat" />
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Image" mdxType="Props" />
    <h2 {...{
      "id": "borderradius",
      "style": {
        "position": "relative"
      }
    }}>{`BorderRadius`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Change the image's border-radius.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Image borderRadius={1} src="http://placekitten.com/50/100" alt="cat with border-radius space 1" />
<Image borderRadius={2} src="http://placekitten.com/50/100" alt="cat with border-radius space 2" />
<Image borderRadius={3} src="http://placekitten.com/50/100" alt="cat with border-radius space 3" />
<Image borderRadius="fill" src="http://placekitten.com/50/100" alt="cat with border-radius 50%" />
<Image borderRadius="sides" src="http://placekitten.com/50/100" alt="cat with border-radius 999px" />
`}</code></pre>
    <h2 {...{
      "id": "height",
      "style": {
        "position": "relative"
      }
    }}>{`Height`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Change the image's height.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Image height={5} src="http://placekitten.com/50/100" alt="cat with height space 5" />
<Image height="auto" src="http://placekitten.com/50/100" alt="cat with height auto" />
<Image height="full" src="http://placekitten.com/50/100" alt="cat with height 100%" />
<Image height="300px" src="http://placekitten.com/50/100" alt="cat with height 300px" />
`}</code></pre>
    <h2 {...{
      "id": "maxheight",
      "style": {
        "position": "relative"
      }
    }}>{`MaxHeight`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Change the image's max-height.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Image maxHeight={5} src="http://placekitten.com/50/100" alt="cat with max-height space 5" />
<Image maxHeight="auto" src="http://placekitten.com/50/100" alt="cat with max-height auto" />
<Image maxHeight="full" src="http://placekitten.com/50/100" alt="cat with max-height 100%" />
<Image maxHeight="300px" src="http://placekitten.com/50/100" alt="cat with max-height 300px" />
`}</code></pre>
    <h2 {...{
      "id": "maxwidth",
      "style": {
        "position": "relative"
      }
    }}>{`MaxWidth`}</h2>
    <p><inlineCode parentName="p">{`default: full`}</inlineCode></p>
    <p>{`You can change the image's max-width. By default it's set to 100%.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Image src="http://placekitten.com/500/200" maxWidth="auto" alt="cat" />
<Image src="http://placekitten.com/500/200" maxWidth="full" alt="cat" />
`}</code></pre>
    <h2 {...{
      "id": "width",
      "style": {
        "position": "relative"
      }
    }}>{`Width`}</h2>
    <p><inlineCode parentName="p">{`default: auto`}</inlineCode></p>
    <p>{`You can change the image's width. By default it's set to auto.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Image src="http://placekitten.com/500/200" width="auto" alt="cat" />
<Image src="http://placekitten.com/500/200" width="full" alt="cat" />
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      